export const environment = {
  production: true,
  uat: false,
  qa: false,
  develop: false,
  environment: 'production',
  baseURL: 'https://api.symplirent.com',
  auth0: {
    client_id: 'fWCg4hzKuLlMk4jz1SDzglNb2sF4PhLj',
    domain: 'symplirent-prod.us.auth0.com',
    audience: 'https://symplirent-prod.us.auth0.com/api/v2/',
    logout_url: 'https://app.symplirent.com//auth/login',
    redirect_uri: 'https://app.symplirent.com/callback'
  }
};
